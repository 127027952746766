import { CSSProperties } from 'react'
import styles from './LoadingBar.module.css'
import classNamesBind from 'classnames/bind'

export type Props = {
  width: string
  className?: string
  style?: CSSProperties
}

const cn = classNamesBind.bind(styles)

export default function LoadingBar({ width, className, style }: Props) {
  return (
    <div
      className={cn('loadingBar', 'tw-h-6', 'tw-rounded-xl', className)}
      style={{ ...style, width: width }}
    />
  )
}
