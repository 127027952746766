import { ReactNode } from 'react'
import LoadingBar from '@crystal-eyes/premeeting/components/elements/Loader/LoadingBar'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

export type Props = {
  children?: ReactNode | ReactNode[]
  loading?: boolean | null
  count?: number
  className?: string
  gap?: number
  width?: number[]
  height?: number
}

export default function LoadingBars({
  loading,
  children,
  count,
  className,
  gap,
  width,
  height,
}: Props) {
  if (!children && !count) return null
  if (!loading) return children
  const childrenLength = Array.isArray(children) ? children.length : 1
  const customCount = count ? count : width?.length
  const barsCount = customCount ? customCount : childrenLength

  const getWidth = (index: number) => {
    if (width && width.length === barsCount) {
      return `${width[index]}%`
    }
    let randomWidth
    // Random width between 60% and 100%
    if (!process.env.STORYBOOK_APP_ENV) {
      randomWidth = Math.floor(Math.random() * 41) + 60
    } else {
      randomWidth = 75
    }
    return `${randomWidth}%`
  }

  return (
    <div
      className={cn(
        'tw-flex',
        'tw-flex-col',
        'tw-justify-start',
        'tw-gap-2',
        className,
      )}
      style={gap ? { gap: `${gap}px` } : {}}
    >
      {Array.from({ length: barsCount }, (_, index) => {
        const uniqueKey = uuidv4()
        return (
          <LoadingBar
            style={{
              height: height ? `${height}px` : '1.5rem',
            }}
            width={getWidth(index)}
            key={uniqueKey}
          />
        )
      })}
    </div>
  )
}
