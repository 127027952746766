'use client'

import { useState } from 'react'
import styles from './IdentityCircle.module.css'
import ProfileBadge from '@crystal-eyes/premeeting/components/profiles/ProfileBadge'
import DefaultImage from '@crystal-eyes/premeeting/components/elements/Avatar/DefaultImage'
import classNamesBind from 'classnames/bind'

type Props = {
  r: number
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  includeVerified?: boolean
  isCrystalUser?: boolean
  photoUrl?: string | null
}

const cn = classNamesBind.bind(styles)

export default function IdentityCircle({
  r,
  className = '',
  style = {},
  onClick,
  onMouseOver,
  onMouseOut,
  includeVerified,
  isCrystalUser,
  photoUrl,
}: Props) {
  const [imageError, setImageError] = useState(false)
  const [preserveAspectRatio, setPreserveAspectRatio] = useState('none')
  const isVerified = includeVerified && isCrystalUser
  return (
    <div
      className={cn('identityCircle', className)}
      style={isVerified ? { width: r * 2, height: r * 2 } : {}}
    >
      {!imageError && photoUrl ? (
        <svg
          className={`${styles.identityCircle} ${className}`}
          viewBox={`0 0 ${r * 2} ${r * 2}`}
          width={r * 2}
          height={r * 2}
          style={style}
        >
          <image
            className={cn('imageCircle')}
            href={photoUrl || ''}
            width={r * 2}
            height={r * 2}
            preserveAspectRatio={preserveAspectRatio}
            clipPathUnits="objectBoundingBox"
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onError={_e => {
              setImageError(true)
            }}
            onLoad={() => {
              setPreserveAspectRatio('xMidYMid slice')
            }}
          />
        </svg>
      ) : (
        <DefaultImage size={r * 2} />
      )}
      {isVerified && (
        <div style={{ position: 'absolute', top: '-0.2rem', right: '-1rem' }}>
          <ProfileBadge style={{ marginLeft: '-25%' }} type="verified" />
        </div>
      )}
    </div>
  )
}
